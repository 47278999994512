import { useState, useEffect } from 'react';

export default function useShowComponent(delay) {
    const [showComponent, setShowComponent] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowComponent(true);
        }, delay);

        return () => clearTimeout(timer);
    }, []);

    return { showComponent };
}
